import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "../../../styles/global.css"
import {
  boxStep,
  subTitle,
  titleStep,
  dessin,
  bloc
} from "../styles/step.module.css"
import yamlData from "../../department/content/step.yaml"
import { Col, Container, Row } from "react-bootstrap"

const Step = () => {

  const images = useStaticQuery(graphql`
    query {
      contact: file(relativePath: { eq: "contact-phone.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      agents: file(relativePath: { eq: "agents.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      acompagnement: file(relativePath: { eq: "acompagnement.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <section className={boxStep}>
      <Container>
        <h2 className={titleStep}>{yamlData.title}</h2>
        <Row>
          <Col lg={4} md={4} xs={12} className={bloc}>
             <Img
                fluid={images.contact.childImageSharp.fluid}
                alt="dessin contacter Dulin"
                className={dessin}
            />
            <h4 className={subTitle}>{yamlData.calendar.subtitle}</h4>
            <p className="paragraphe">{yamlData.calendar.paragraphe}</p>
          </Col>
          <Col lg={4} md={4} xs={12} className={bloc}>
            <Img
                fluid={images.agents.childImageSharp.fluid}
                alt="dessin agents Dulin"
                className={dessin}
            />
            <h4 className={subTitle}>{yamlData.users.subtitle}</h4>
            <p className="paragraphe">{yamlData.users.paragraphe}</p>
          </Col>
          <Col lg={4} md={4} xs={12} className={bloc}>
            <Img
                fluid={images.acompagnement.childImageSharp.fluid}
                alt="dessin accompagnement personnalisé Dulin"
                className={dessin}
            />   
            <h4 className={subTitle}>{yamlData.comments.subtitle}</h4>
            <p className="paragraphe">{yamlData.comments.paragraphe}</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Step
