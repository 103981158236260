import React from "react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import SlideEpaviste from "../components/landing/slideEpaviste"
import Description from "../components/landing/description"
import Document from "../components/landing/document"
import Faq from "../components/landing/faq"
import Step from "../templates/department/components/step"
import Questions from "../templates/department/components/questions"

const DestructionVehicule = () => (
  <Layout>
    <Seo
      title="Epaviste Dulin, épaviste agrée pour la désctruction de véhicule en Ile de France"
      description="Formulaire de contact, déscrition des formalités pour la mise en destruction d'un véhicule par nos service"
    />
    <SlideEpaviste 
      backgroundImage={"destruction"}
      para={"Pour convenir d'un rendez-vous afin de mettre en destruction votre véhicule hors d'usage,"}
    />
    <Description
      titre={"Quel est le rôle de notre centre de destruction ?"}
      para={"Nous intervenons de manière gratuite en Ile de France pour mettre en destruction votre véhicule hors d'usage. À l’aide de nos camions de remorquages, nous venons prendre votre épave pour la transporter dans notre centre de recyclage agréé. Ce centre permet de traiter le véhicule afin d’éliminer ses éléments toxiques tels que l’huile du moteur, le caoutchouc, etc. Le service est entièrement gratuit, vous devez simplement fournir certains documents, le jour de l'enlèvement, nous vous remettons un certificat de destruction."}
    />
    <Step />
    <Document 
      titre={"Les documents à fournir pour faire detruire votre véhicule"}
    />
    <Questions 
      page={"destruction"}
    />
    <Faq />
  </Layout>
)

export default DestructionVehicule
